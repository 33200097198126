.alert {
    &__title {
        font-size: 22px;
        padding-bottom: 4px;
    }
    position: fixed;
    z-index: 11;
    max-width: 500px;
    width: 100%;
    text-align: center;
    padding: 20px 5px;
    border-radius: 4px;
    background-color: #6CB8AB;
    top: 100px;
    color: white;
    font-size: 20px;
    right: 100px;
    animation: fadein 4s ease 0s;
}

@keyframes fadein {
    0% {
        top: 0px;
    }
    5% {
        top: 100px;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 500px) {
    .alert {
        right: 0;
        left: 0;
    }
}