.form {
    max-width: 508px;
    width: 100%;
    display: flex;
    margin: auto;
    margin-bottom: 20px;
    flex-direction: column;
    &__submit {
        width: 317.63px;
        height: 70px;
        text-align: center;
        background: #FFFFFF;
        font-weight: 600;
        border-radius: 8px;
        font-size: 24px;
        border: 2px solid transparent;
        &_dark {
            background-color: #313131;
            color: #fff;
            &:disabled {
                opacity: .5;
            }
        }
        &:hover {
           border-color: rgba(158, 215, 211, 0.5);
        }
        &:disabled {
            &:hover{
                cursor: default;
                border-color: transparent;
            }
        }
    }
}

.form-groupe {
    width: 100%;
    &__label {
        font-weight: 600;
        padding-left: 24px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #333333;
        opacity: 0.5;
        transition: .1s;

    }
    textarea {
        height: 100px;
        resize: none;
        padding-top: 10px;
    }
    &__input {
        padding-left: 24px;
        height: 24px;
        max-width: 440px;
        width: 100%;
        display: block;
        font-weight: 600;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #494B4D;
        outline: none;
        &:hover {
           border-bottom: 2px solid #494B4D;;
        }
        transition: .1s;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: 0 0 0 30px rgba(102, 163, 177, 0.01) inset !important;
            -webkit-box-shadow: 0 0 0 30px rgba(102, 163, 177, 0.01) inset !important;

        }
        &:focus {
            border-color: rgb(20, 21, 22);
        }
    }
    &_invalid {
        .form-groupe__label {
            color: rgb(255, 55, 55);
        }
        .form-groupe__input {
            border-color:rgb(255, 55, 55);
        }
    }
}

.form-groupe:not(:last-child) {
    padding-bottom: 32px;
}

.date-picker-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 30px 0;
}

.select-wrapper {
    width: 100%;
    color: #000000;
    select {
        color: #000000;
        width: 100%;
        font-weight: 600;
        opacity: 1;
        border: none;
        border-bottom: 1px solid #494B4D;
        padding-left: 24px;
        height: 24px;
        outline: none;
        margin-top: 5px;
        cursor: pointer;
        &:hover {
            border-bottom: 2px solid #494B4D;;
         }
    }
}

.MuiFormHelperText-root.Mui-error {
    display: none;
}

.form-groupe__label_invalid {
    color: red;
}
.form-groupe__select_invalid {
    border-color: red !important;
}
.day-picker-item input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

