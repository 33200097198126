.noskew * {
    transition: .5s;
}
.noskew {
    display: block;
    position: absolute;
    height: 100%;
    width: 60vw;
    top: 0;
    transform: skew(20deg); 
    .title {
        padding-top: 60px;
        font-size: 48px;
        line-height: 59px;
        color: #313131;
        position: relative;
        &::before {
            content: '';
            bottom: 0;
            position: absolute;
            height: 3px;
            background-color:  #9FCDD6;
            width: 111px;
        }
    }
    .accord__item-desc {
        padding-top: 48px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        opacity: 0;
        transition: .3s;
        color: #EEEEEE;
    }
}

.click-trigger {
    right: 0;
    left: 0;
    bottom: 20px;
    position: absolute;
    text-align: center;
    color: white;
    width: 80%;
}

.accord-content {
    max-width: 600px;
    position: absolute;
    left: 5%;
}

.accord {
    height: 100%;
    overflow: hidden;
    position: relative;
    &__item {
        background: transparent;
        cursor: pointer;
        height: 100%;
        transition: width .3s;
        width: 55%;
        position: absolute;
        transform: skew(-20deg);
        overflow: hidden;
        &_first {
            .title {
                margin-left: 20%;
                color: #EEEEEE;
            }
            left: -7%;
            .noskew {
                transition: all .3s;
                .background {
                    &:hover {
                        transform: scale(1.03);
                    }
                    position: absolute;
                    transition: all .5s;
                    top: 0;
                    width: 100%;
                    min-height: 100%;
                    background: linear-gradient(114.07deg, #31313100 0%, rgba(255, 255, 255, 0) 123.66%), url(../../assets/images/accord-ship.png) center center / cover no-repeat;
                }
                left: 3%;
            }
        }
        &_second {
            .accord-content {
                left: unset;
                right: 10%;
            }   
            .title {
                text-align: right;
                &::before {
                    right: 0;
                }
            }
            .click-trigger {
                color: #313131;
                font-size: 16px;
                font-weight: normal;
                width: 100%;
                line-height: 16px;
                height: 30px;
            }
            right: 0%;
            .noskew {
                transition: all .3s;
                .background {
                    &:hover {
                        transform: scale(1.03);
                    }
                    position: absolute;
                    transition: all .5s;
                    top: 0;
                    left: 4%;
                    width: 100%;
                    min-height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                right: 0;
            }
        }
    }
}

.accord.active-first {
    .accord__item-desc {
        opacity: 1;
    }
    cursor: default;
    .accord__item {
        &_first {
            .click-trigger {
                display: none;
            }
            .title {
               margin-left: 0;
            }
            z-index: 5;
            width: 75%;
            .noskew {
                width: 80vw;
                .background {
                    background: linear-gradient(114.07deg, #313131 0%, rgba(255, 255, 255, 0) 123.66%), url(../../assets/images/accord-ship.png) center center / cover no-repeat;
                    &:hover {
                        cursor: default;
                        transform: none;
                    }
                    left: 5%;
                }
            }
        }
        &_second {
            width: 45%;
            .noskew {
                width: 50vw;
            }
        }
    }
}
.accord.active-second {
    .accord__item {
        &_first {
            .click-trigger {
                width: 67%;
            }
            width: 35%;
            z-index: 2;
            .noskew {
                width: 50vw;
            }
        }
        &_second {
            .click-trigger {
                display: none;
            }
            width: 75%;
            .noskew {
                width: 80vw;
                .background {
                    .background_text {
                        flex-direction: row;
                    }
                    cursor: default;
                    &:hover {
                        transform: none;
                    }
                }
            }
        }
    }
}

.accord .form {
    opacity: 0;
    position: relative;
    display: none;
    z-index: 2;
}
.accord.active-second .form {
    opacity: 1;
    display: block;
}

.accord__item_second .noskew .background {
    display: flex;
}

.background_text {
    font-weight: 800;
    z-index: 1;
    position: absolute;
    top: 0;
    transition: .1s;
    bottom: 0;
    left: 0px;
    right: 0px;
    max-width: 68%;
    margin: auto;
    font-size: 96px;
    line-height: 117px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: rgba(158, 215, 211, 0.5);
}


@media screen and (max-width: 1100px) {
    .accord__item .accord__item_first,
    .accord__item .accord__item_second {
        transition: height .3s;
    }
    .accord__item_first {
        left: -11%;
    }
    .accord__item_first .noskew {
        left: 10%;
    }
    .accord.active-first .accord__item_first .noskew {
        .accord__item-desc {
            max-width: 550px;
        }
        left: 5%;
    }
    .accord-content {
        max-width: 100%
    }
}
@media screen and (max-width: 900px) {



    .background_text {
        max-width: 100%;
        margin: auto;
        font-size: 36px;
        line-height: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: rgba(158, 215, 211, 0.5);
    }

    .section-accord {
        height: 600px;
    }
    .accord {
        &-content {
            height: 100%;
        }
        display: flex;
        flex-direction: column;
    }
    .accord.active-first .accord__item-desc {
        display: flex;
        align-items: center;
        padding-bottom: 50px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 18px;
        line-height: 20px;
    }
    .accord__item {
        width: 50%;
        .title {
            top: calc(50% - 29px);
            left: 0;
            right: 0;
            padding-top: 0;
            position: absolute;
        }
    }
    .accord__item_first {
        left: 0%;
    }
    .accord.active-first .accord__item_first .noskew .background {
        display: flex;
        align-items: flex-end;
        left: 0%;
    }
    .accord.active-first .accord__item_first,
    .accord.active-second .accord__item_second {
        width: 100%;
    }

    .accord__item_first .title {
        margin-left: 0;
        text-align: center;
    }

    .accord.active-first .accord__item_first .noskew {
        width: 100%;
    }
    
    .accord.active-first .accord__item_second,
    .accord.active-second .accord__item_first {
        width: 25%;
    }

    .accord.active-first .accord__item_first {
        left: 0;
    }

    .accord {
        justify-content: flex-start;
        overflow: hidden;
        position: relative;
        &__item {
            transition: height .6s;
            height: 100%;
            width: 100%;
            position: static;
            transform: unset;
            .noskew {
                .accord__item-desc {
                    transition: unset;
                }
                position: static;
                .accord-content {
                    left: 0;
                }
            }
    }}
    .noskew {
        transform: skew(0deg);;
    }

    .accord__item_first .noskew {
        left: 0;
        width: 100%;
        .background {
            left: 0;
        }
    }

    .accord__item_second .noskew {
        .background {
            left: 0;
            &_text {
                background-color: #ffffff;
            }
        }
        width: 100%;
        .accord-content {
            width: 100%;
            z-index: 3;
            left: 0;
            .title {
                text-align: center;
                &:before {
                    right: unset;
                }
            }
        }
    }

    .noskew {
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
    }

    .accord.active-first {
        .accord__item {
            .accord-content {
                height: 100%;
                display: flex;
                align-items: flex-end;
            }
            &_first {
                max-height: 100%;
                .title {
                   top: 20px;
                }
                width: 100%;
                height: 80;
                .noskew {
                    width: 100%;
                    .background {
                        &:hover {
                            cursor: default;
                            transform: none;
                        }
                        left: 0%;
                    }
                }
            }
            &_second {
                .title {
                    top: 20px;
                }
                width: 100%;
                height: 20%;
                .noskew {
                    width: 100%;
                }
            }
        }
    }

    .accord.active-second {
        .accord__item {
            &_first {
                width: 100%;
                height: 20%;
                .title {
                    top: 20px;
                }
                .noskew {
                    width: 100%;
                }
            }
            &_second {
                max-height: 100%;
                width: 100%;
                .noskew {
                    width: 100%;
                    .background {
                        display: flex;
                        
                        .form-groupe__input {
                            max-width: 100%;
                            padding: 0 15px;
                        }
    
                        .form__submit {
                            margin: auto;
                            display: block;
                        }
                        .accord-content {
                            width: 100%;
                            height: 60px;
                        }
                        .title {
                            top: 20px;
                            &::before {
                                display: none;
                            }
                        }
                        &_text {
                            display: none;
                        }
                        left: 0;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 350px) {
    .accord.active-first .accord__item-desc {
        font-size: 16px;
        line-height: 16px;
    }
}