.burger-icon {
    width: 30px;
    height: 22px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
  }
  
  .burger-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #313131;
    border-radius: 19px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .burger-icon span:nth-child(1) {
    top: 0px;
  }
  
  .burger-icon span:nth-child(2),.burger-icon span:nth-child(3) {
    top: 9px;
  }
  
  .burger-icon span:nth-child(4) {
    top: 18px;
  }
  
  .burger-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  .burger-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .burger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .burger-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

@media screen and (max-width: 850px) {
    .burger-icon {
        display: block;
    }
}
  