@import "../../variables.scss";

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #eeeeee;
    overflow: auto !important;
    padding-right: 0 !important;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}
// titles 
.second-title {
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
}

h5 {
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
}

.white-text {
    color: #fff;
}
.dark-text {
    color: #313131;
}
.accent-text {
    color: $accent-color;
}

.container {
    max-width: 1232px;
    margin: auto;
    padding: 0 15px;
}

.section-first {
    height: 100vh;
    padding-top: 70px;
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.first-btn {
    position: relative;
    z-index: 3;
}

.main-title {
    margin: 0 auto;
    padding-bottom: 10px;
    max-width: 80%;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
}

.subtitle {
    margin: 0;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    font-weight: normal;
}
// buttons
.button {
    border: none;
    cursor: pointer;
    outline: none;
    transition: background-color .3s;
}
.btn-regular {
    padding: 12px 58px;
    color: #494B4D;
    font-weight: 600;
    font-size: 24px;
    background: $accent-color;
    border-radius: 8px;
    &:hover {
        background: darken($color: $accent-color, $amount: 20%);
    }
}
// main page 
// first section
.section-first-content {
    display: flex;
    color: #313131;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 3;
}
// second section
.section-second {
    padding: 170px 0;
}
// section three 
.section-three {
    padding-top: 120px;
} 
// section four 
.section-contacts {
    padding: 202px 0;
}

.section-slider {
    padding-top: 90px;
    padding-bottom: 160px;
    .our-team-title {
        padding-bottom: 32px;
    }
}

.section-our-clients {
    padding: 170px 0 165px 0;
    background-color: #494B4D;
    .our-clients__title {
        padding-bottom: 32px;
    }
}
.company-information {
    margin: 40px 0;
    margin-bottom: 0;
    height: 460px;
    display: flex;
    align-items: center;
    background-image: url(../../assets/images/map.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &-wrapper {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
    }
}

.section-accord {
    height: 542px;
    margin-top: 40px;
}

.adaptive-img {
    width: 100%;
}

.align-center {
    display: flex;
    align-items: center;
}

.company-information-wrapper {
    flex-wrap: nowrap;
    width: 50%;
}

.footer {
    padding: 100px 0;
}

@media screen and (max-width: 1150px) {
    .main-title {
        font-size: 44px;
        line-height: 60px;
    }
    .subtitle {
        font-size: 30px;
        padding: 0 15px;
    }
    .company-information-wrapper {
        width: 95%;
    }
}

@media screen and (max-width: 800px) {
    .company-information {
        margin: 80px 0;
        height: unset;
    }
    section {
        padding: 0 !important;
    }
    section .container {
        padding: 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .company-information-wrapper {
        justify-content: center;
        align-items: center;

        flex-direction: column;
        width: 100%
    }
    .company-information {
        height: auto;
        margin: 40px 0;
        width: 20px 0;
    }
    .main-title {
        font-size: 44px;
        line-height: 44px;
    }
    .subtitle {
        font-size: 30px;
        line-height: 1em;
    }
}


// test
.app-second-theme {
    .header-wrapper {
        .nav {
            color: #eee;
        }
    }
    .section-first-content {
        color: #eee;
    }
    .header_black {
        .nav {
            color: #313131;
        }
    }
}

// in view 
.in-view-parent > div {
    height: 100%;
    width: 100%;
}