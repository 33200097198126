.select-group {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: 220px 220px 220px 220px;
    text-transform: uppercase;
    color: #161515;
    overflow: hidden;
    .sm:hover {
        .select-group__item__title {
            opacity: 0;
        }
    }
    &__item {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        background-color: #eeeeee;
        &__title {
            letter-spacing: -3px;
            position: absolute;
            top: 24px;
            transition: opacity .3s;
            left: 24px;
            right: 0;
            font-weight: 500;
            font-size: 48px;
            line-height: 59px;
        }
        &__content {
            top: 100%;
            position: absolute;
            opacity: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
            transition: top ease .3s;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: flex-end;
            padding: 0 24px 30px 24px;  
            background: rgba(255, 255, 255, 0.6);
        }
        &__text {
            max-width: 588px;
        }
        &:hover {
            .group-item-container {
               opacity: .5;
            }
            .select-group__item__content {
                top: 0;
                opacity: 1;
            }
        }
        &_1 {
            grid-row: 1 / span 2;
            grid-column: 1 / span 2 ;
        }
        &_2 {
            grid-column: 3;
            grid-row: 1;
        }
        &_3 {
            grid-column: 3 ;
            grid-row: 2;
        }
        &_4 {
            grid-row: 3;
            grid-column: 1 ;
        }
        &_5 {
            grid-row: 4 / span 3;
            grid-column: 1;
        }
        &_6 {
            grid-row: 3 / span 4;
            grid-column: 2 / span 3;
        }
        
    }
}

.group-item-container {
    height: 100%;
    opacity: 1;
    background-size: cover;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media screen and (max-width: 1100px) {
    .select-group {
        grid-template-columns: 50%;
        grid-template-rows: 300px 300px 300px;
        &__item {
            &__title {
                font-size: 38px;
                top: 28px;
            }
            &__content {
                font-size: 18px;
            }
            &_1 {
                grid-row: 1;
                grid-column: 1;
            }
            &_2 {
                grid-column: 2;
                grid-row: 1;
            }
            &_3 {
                grid-column: 1 ;
                grid-row: 2;
            }
            &_4 {
                grid-column: 2 ;
                grid-row: 2 ;
            }
            &_5 {
                grid-row: 3;
                grid-column: 1 / span 2;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .offers {
        .container {
            padding: 0 0;
        }
    }
    .select-group {
        display: flex;
        flex-direction: column;
        &__item {
            height: 50vh;
        }
    }
}
// old type of opacity
// .select-group {
//     &__item:nth-child(2) {
//         .group-item-container {
//             opacity: .5;
//         }
//         &:hover {
//             .group-item-container {
//                 opacity: 1;
//             }
//         }
//     } 
// }
