.logo {
    font-weight: bold;
    font-family: Montserrat;
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    cursor: pointer;
    a {
        background: linear-gradient(90deg, #73C7B9 33%, rgb(195, 240, 232) 99%, #ffffff);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        height: 41px;
        display: flex;
        align-items: center;
    }
}
