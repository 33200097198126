.request-modal {
    position: fixed;
    right: 30px;
    padding: 50px 100px;
    top: -100%;
    background-color: #fff;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: top .3s;
    &_open {
        top: 20px;
    }
    .close-btn {
        position: absolute;
        top: 20px;
        left: 30px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        outline: transparent;
        border: none;
        cursor: pointer;
    }
    form {
        margin: 0 auto;
        .form-groupe__input {
            max-width: unset;
        }
    }
}

@media screen and (max-width: 540px ) {
    .request-modal {
        left: 0px;
        //top: 0;
        right: unset;
        width: 100%;
        padding: 100px 20px;
    }
}
@media screen and (max-width: 340px) {
    .request-modal {
        padding: 30px 10px;
        .form__submit {
        width: 100%;
        }
        .close-btn {
            left: 0;
            top: 0;
            width: 30px;
        }
    }
    .date-picker-wrapper {
        flex-direction: column;
    }
    
    
}