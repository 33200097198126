
.login-page {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    img {
        padding-right: 8px;
    }
}
.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    transition: .2s;
    background: linear-gradient(99.57deg, #5453539b 0%, rgba(238, 238, 238, 0) 55.54%);
    padding-top: 20px;
    padding-bottom: 20px;
}
.header_black {
    z-index: 10;
    background: #eeeeee;
}

.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}