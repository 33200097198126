.contacts-list {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;

    &__item:not(:last-child) {
        padding-bottom: 3px;
    }
    &__item a{
        transition: color .3s;
        &:hover {
            cursor: pointer;
            color: rgb(192, 228, 221);
        }
    }
    
}