@import "../../variables.scss";

.nav {
    padding: 0;
    color: #313131;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    list-style: none;
    margin: 0;
    &-column {
        flex-direction: column;
        .nav__item {
            padding: 0;
            &:not(:last-child) {
                padding-bottom: 16px;
            }
        }
    }
    &__item {
        padding: 0 12px;
        &:hover {
            color: lighten($color: $accent-color, $amount: 20%);
            cursor: pointer;
        }
        &::first-letter{
            text-transform: uppercase;
        }
        a {
            transition: color .3s;
            cursor: pointer;
            &.active {
                color: $accent-color;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .navigation.header-navigation {
        display: block;
        position: absolute;
        height: 100vh;
        display: flex;
        width: 60%;
        right: -100%;
        transition: .3s;
        top: 0;
        background-color: #ffffff;
        .nav {
            padding-right: 15px;
            width: 100%;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            &__item {
                padding: 10px 0;
            }
        }
        &.opened {
            right: 0%;
        }
    }
    
}

