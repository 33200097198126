.circular-chart-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 200px;
    margin: auto;
    border-radius: 50%;
}

.circular-chart {
    display: block;
    flex-wrap: wrap;
    transform: rotate(55deg);
}
  
  .circle {
    stroke: #9CCCD5;
    fill: none;
    stroke-width: 2.5;
    animation: progress-back 1s ease-out forwards;
    &.active {
        animation: progress 1s ease-out forwards;
    }
  }

.chart {
    width: 108px;
    display: flex;
    flex-direction: column;
    height: 108px;
    position: absolute;
    justify-content: space-around;
    text-align: center;
    top: calc(50% - 54px);
    left: calc(50% - 54px);
    &__value {
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
    }
    &__desc {
        font-size: 14px;
        line-height: 20px;
    }
}
  
@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
    100% {
        
        stroke-dasharray: 95 100;
        
    }
}
@keyframes progress-back {
    0% {
        stroke-dasharray: 95 100;
    }
    100% {
        stroke-dasharray: 0 100;
    }
}

@media screen and(max-width: 840px) {
    .circular-chart-wrapper {
        margin: auto;
        .circle {
            width: 50%;
        }
    }
    
}
@media screen and(max-width: 500px) {
    .circular-chart-wrapper:not(:last-child) {
        margin-bottom: 20px;
    }
    .circular-chart-wrapper {
        max-width: calc(100% - 20px);
        width: 60%;
    }
}