.social-list {
    display: flex;
    &__item:not(:last-child) {
        padding-right: 32px;
    }
    &__item a{
        display: flex;
        transition: opacity .3s;
    }
    &__item a:hover {
        opacity: .5;
    }
}