.bg-video {
    position: absolute;
    top: 0;
    width: 100%;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    &__item {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh;
        min-width: 100vw;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 1;
        background-size: cover;
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-width: 100%; 
            min-height: 100%;
            width: auto;
            height: auto; 
        }
    }
}

.bg-video-buttons {
    position: absolute;
    left: 10px;
    top: 42%;
    z-index: 10;
    &__item {
        border: 1px solid rgb(255, 255, 255);
        opacity: .5;
        color: #fff;
        width: 50px;
        height: 25px;
        border-radius: 4px;
        margin: 15px 0;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        &_active {
            background-color: rgba(255, 255, 255, 0.952);
            color: #313131;
        }
        &:hover {
            opacity: 1;
        }
    }
}

.d-none {
    display: none;
}


@media screen and (max-width: 768px) {
    .bg-video__item {
        margin-left: -50%;
    }
}
// @media screen and (max-width: 425px) {
//     .bg-video__item {
//         margin-left: -150%;
//     }
// }

@supports (object-fit: cover) {
    .bg-video__item > video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}