.day-picker-item {
    text-align: center;
    position: relative;
    .form-groupe__label {
        padding-left: 0;
    }
    input {
        width: 100px;
        text-align: center;
        height: 24px;
        display: block;
        background-color: transparent;
        border: none;
        outline: none;
        transition: .1s;
    }
}